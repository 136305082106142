export type FetchDefaultOptions = {
  cache: RequestCache;
  credentials: RequestCredentials;
  headers: HeadersInit;
  mode: RequestMode;
  redirect: RequestRedirect;
  referrerPolicy: ReferrerPolicy;
};

export interface CommonApiOptions {
  checkTokenValidity?: boolean;
  emitErrorEvent?: boolean;
  headers?: HeadersInit & AuthorizationHeaders;
  includeAuth?: boolean;
  omitNotFoundErrorEmit?: boolean;
  returnJson?: boolean; // Should be used for fetching errors. Errors on mutation should be handled in place of use
}

export type QueryParamsSingleValue = string | number | undefined;
export type QueryParamsValue =
  | QueryParamsSingleValue
  | Array<QueryParamsSingleValue>;
export type QueryParams = Record<string, QueryParamsValue>;

export interface GETOptions extends CommonApiOptions {
  queryParams?: QueryParams;
}

export type AuthorizationHeaders = {
  Authorization?: string;
};

export class ResponseError extends Error {
  public readonly timestamp: string;
  public readonly cause: Record<string, any>;
  public readonly code: string | undefined;
  public readonly stacktrace: string;
  public readonly response: Response;
  constructor(
    timestamp: string,
    cause: Record<string, any>,
    code: string | undefined,
    stacktrace: string,
    response: Response,
  ) {
    super(`ResponseError (${response.status}): ${response.statusText}`);
    this.timestamp = timestamp;
    this.cause = cause;
    this.code = code;
    this.stacktrace = stacktrace;
    this.response = response;
  }

  get errorDetails(): string {
    let details = '';
    if (this.cause.message) {
      details += `message: "${this.cause.message}"\n`;
    }
    details += `status code: ${this.response.status}\n`;
    details += `code: "${this.code}"\n`;
    details += `url: "${this.response.url}"\n`;
    details += `timestamp: "${this.timestamp}"\n`;
    return details;
  }
}

export enum AuthenticationErrorCause {
  expiredToken = 'expired_token',
  invalidToken = 'invalid_token',
}

export class AuthenticationError extends Error {
  constructor(cause: string) {
    super(`AuthenticationError (${cause})`);
    this.cause = cause;
  }
}

export type ApiMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
