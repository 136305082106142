import { AppConfig, AppFlavor } from '@appconf/models';

import { procivisThemeConfig, procivisThemeImages } from './theme';

const procivisConfig: AppConfig = {
  availableLanguages: ['en', 'de'],
  docs: 'https://docs.procivis.ch',
  flavor: AppFlavor.procivis,
  mocksEnabled: false,
  name: 'Procivis One Desk',
  release: process.env.REACT_APP_RELEASE ?? 'dev',
  responseErrorNerdMode: true,
  sentry: {
    dsn: 'https://3065824c119e4268aef7d1f62602fae4@o153694.ingest.sentry.io/4505114836140032',
  },
  slug: 'one-desk',
  theme: {
    config: procivisThemeConfig,
    images: procivisThemeImages,
  },
};

export default procivisConfig;
