export enum OrganisationPermission {
  CredentialDetail = 'CREDENTIAL_DETAIL',
  CredentialIssue = 'CREDENTIAL_ISSUE',
  CredentialList = 'CREDENTIAL_LIST',
  CredentialReactivate = 'CREDENTIAL_REACTIVATE',
  CredentialRevoke = 'CREDENTIAL_REVOKE',
  CredentialSchemaCreate = 'CREDENTIAL_SCHEMA_CREATE',
  CredentialSchemaDelete = 'CREDENTIAL_SCHEMA_DELETE',
  CredentialSchemaDetail = 'CREDENTIAL_SCHEMA_DETAIL',
  CredentialSchemaList = 'CREDENTIAL_SCHEMA_LIST',
  CredentialSchemaShare = 'CREDENTIAL_SCHEMA_SHARE',
  CredentialShare = 'CREDENTIAL_SHARE',
  CredentialSuspend = 'CREDENTIAL_SUSPEND',
  DidCreate = 'DID_CREATE',
  DidDeactivate = 'DID_DEACTIVATE',
  DidDetail = 'DID_DETAIL',
  DidList = 'DID_LIST',
  IntegrationCreate = 'OPENID_PROVIDER_CREATE',
  IntegrationDelete = 'OPENID_PROVIDER_DELETE',
  IntegrationDetail = 'OPENID_PROVIDER_DETAIL',
  IntegrationEdit = 'OPENID_PROVIDER_EDIT',
  IntegrationList = 'OPENID_PROVIDER_LIST',
  KeyCreate = 'KEY_CREATE',
  KeyDetail = 'KEY_DETAIL',
  KeyList = 'KEY_LIST',
  ProofDetail = 'PROOF_DETAIL',
  ProofIssue = 'PROOF_ISSUE',
  ProofList = 'PROOF_LIST',
  ProofSchemaCreate = 'PROOF_SCHEMA_CREATE',
  ProofSchemaDelete = 'PROOF_SCHEMA_DELETE',
  ProofSchemaDetail = 'PROOF_SCHEMA_DETAIL',
  ProofSchemaList = 'PROOF_SCHEMA_LIST',
  ProofSchemaShare = 'PROOF_SCHEMA_SHARE',
  ProofShare = 'PROOF_SHARE',
  TrustAnchorCreate = 'TRUST_ANCHOR_CREATE',
  TrustAnchorDelete = 'TRUST_ANCHOR_DELETE',
  TrustAnchorDetails = 'TRUST_ANCHOR_DETAIL',
  TrustAnchorEdit = 'TRUST_ANCHOR_EDIT',
  TrustAnchorList = 'TRUST_ANCHOR_LIST',
  TrustEntityCreate = 'TRUST_ENTITY_CREATE',
  TrustEntityDetails = 'TRUST_ENTITY_DETAIL',
  TrustEntityEdit = 'TRUST_ENTITY_EDIT',
  TrustEntityWithdraw = 'TRUST_ENTITY_WITHDRAW',
}

export type Organisation = {
  displayName: string;
  id: string;
  isActive: boolean;
  name: string;
  permissions: OrganisationPermission[];
};
