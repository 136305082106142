/*
 * Only for models used local to the "settings" folder.
 * For everything else use the "../models" folder.
 */
import { ThemeConfig as AntThemeConfig } from 'antd';

export enum AppFlavor {
  procivis = 'procivis',
  procivisDev = 'procivisDev',
  zug = 'zug',
}

export type LanguageCode = 'en' | 'de';

export interface AppConfig {
  apiBaseUrl?: string;
  availableLanguages: LanguageCode[];
  docs: string;
  flavor: AppFlavor;
  mocksEnabled: boolean;
  name: string;
  release: string;
  responseErrorNerdMode: boolean;
  sentry: SentryConfig;
  slug: string;
  theme: ThemeConfig;
}

// --- Sentry

export interface SentryConfig {
  dsn?: string;
}

// --- Theme

export interface ThemeConfig {
  colors?: ThemeColors;
  config: AntThemeConfig;
  images: ThemeImages;
}

export interface ThemeImages {
  loginBgURI?: string;
  logoURIs?: string[];
}

export interface ThemeColors {
  background: string;
  componentBackground: string;
  neutral: string;
  onPrimary: string;
  onPrimaryVariant: string;
  orgAdminHighlight: string;
  orgHighlight: string;
  primary: string;
  primaryVariant: string;
  text: string; // Should be the same as the "@text-color" AntD less variable
}
