/* eslint-disable simple-import-sort/imports */
/* eslint-disable import/first */

import 'react-app-polyfill/stable'; // Enable Browserslist based polyfills. This must be the first line!
import '@procivis/react-components/dist/index.css'; // Component library styles
import './styles/antd-overrides.scss'; // Antd styles overrides
import './styles/one.scss'; // Global One styles
import './index.scss'; // App styles

// --- Sentry Init - Before everything else to capture as much as possible
import { initSentry } from '@services/error/Sentry';
// ---

import appConfig from '@appconf/config';
import { RootStoreProvider } from '@contexts/RootStore/RootStoreContext';
import { ThemeConfigProvider } from '@contexts/ThemeConfig/ThemeConfigContext';
import { LoadingView } from '@procivis/react-components';
import queryClient from '@services/api/query';
import i18n from '@services/i18n/i18next'; // Keep after AppConfig
import RootStore from '@store/RootStore';
import { i18nInitialization } from '@utils/i18n/i18n';
import { ConfigProvider as AntConfigProvider } from 'antd';
import React, { FC, Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { NotificationContextProvider } from '@contexts/Notification/Notification';

initSentry();

export interface IndexProps {
  flavor?: string;
}

const Index: FC<IndexProps> = ({ flavor }) => {
  // Bootstrap
  useEffect(() => {
    const bootstrap = async () => {
      // --- i18n Init
      await i18nInitialization(i18n);

      // --- Store Init
      await RootStore.languageStore.changeLanguage(i18n.languages[0]);
    };
    bootstrap();
  }, []);

  return (
    <AntConfigProvider theme={appConfig.theme.config}>
      <ThemeConfigProvider value={appConfig.theme}>
        <RootStoreProvider value={RootStore}>
          <QueryClientProvider client={queryClient}>
            <NotificationContextProvider>
              <BrowserRouter>
                <Suspense fallback={<LoadingView />}>
                  <App flavor={flavor} />
                </Suspense>
              </BrowserRouter>
            </NotificationContextProvider>
          </QueryClientProvider>
        </RootStoreProvider>
      </ThemeConfigProvider>
    </AntConfigProvider>
  );
};

// Set Page Title
document.title = `${appConfig.name}`;

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Index flavor={appConfig.flavor} />);
