/* eslint-disable @typescript-eslint/no-empty-function */
import appConfig from '@appconf/config';
import ResponseErrorNotificationDetails from '@components/notification/ResponseErrorNotificationDetails';
import { ResponseError } from '@models/types/Api';
import { notification } from 'antd';
// eslint-disable-next-line no-restricted-imports
import { ArgsProps } from 'antd/lib/notification';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Notification.module.css';

interface NotificationProps extends Omit<ArgsProps, 'message'> {}

type NotificationContextValue = {
  error: (props: ArgsProps) => void;
  genericActionError: (props?: ArgsProps) => void;
  responseError: (
    responseError: ResponseError,
    props?: NotificationProps,
  ) => Promise<void>;
  success: (props: ArgsProps) => void;
  warning: (props: ArgsProps) => void;
};

const NotificationContext = createContext<NotificationContextValue>({
  error: () => {},
  genericActionError: () => {},
  responseError: () => Promise.reject(),
  success: () => {},
  warning: () => {},
});

export const NotificationContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification({
    stack: { threshold: 2 },
  });

  const contextValue = useMemo(
    () => ({
      error: (props: ArgsProps) =>
        api.error({
          duration: null,
          placement: 'bottomRight',
          props: { 'data-testid': 'error-notification' },
          ...props,
        }),
      genericActionError: (props?: ArgsProps) =>
        api.error({
          duration: null,
          placement: 'bottomRight',
          props: { 'data-testid': 'error-notification' },
          ...props,
          message: t('common.genericErrorNotification'),
        }),
      responseError: async (
        responseError: ResponseError,
        props?: NotificationProps,
      ) => {
        let message;

        if (responseError.code) {
          try {
            const errorKey = `common.error.${responseError.code}`;
            message = t(errorKey);
            if (message === errorKey) {
              message = t('common.error.descriptionWithCode', {
                errorCode: responseError.code,
              });
            }
          } catch (err) {
            message = t('common.error.descriptionWithCode', {
              errorCode: responseError.code,
            });
          }
        } else {
          message = t('common.error.description');
        }

        if (appConfig.responseErrorNerdMode) {
          return api.error({
            className: styles.responseErrorNotification,
            description: (
              <ResponseErrorNotificationDetails responseError={responseError} />
            ),
            duration: null,
            message,
            placement: 'bottomRight',
            props: { 'data-testid': 'error-notification' },
            ...props,
          });
        } else {
          api.error({
            duration: null,
            message,
            placement: 'bottomRight',
            props: { 'data-testid': 'error-notification' },
            ...props,
          });
        }
      },
      success: (props: ArgsProps) =>
        api.success({
          duration: 3,
          placement: 'bottomRight',
          props: { 'data-testid': 'success-notification' },
          ...props,
        }),
      warning: (props: ArgsProps) =>
        api.warning({
          duration: null,
          placement: 'bottomRight',
          props: { 'data-testid': 'warning-notification' },
          ...props,
        }),
    }),
    [api, t],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
