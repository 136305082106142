import { CopyOutlined } from '@ant-design/icons';
import { useNotification } from '@contexts/Notification/Notification';
import { ResponseError } from '@models/types/Api';
import { Button } from '@procivis/react-components';
import { Collapse as AntCollapse, Input as AntInput } from 'antd';
import copy from 'copy-to-clipboard';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ResponseErrorNotificationDetails.module.css';

export type ResponseErrorNotificationDetailsProps = {
  responseError: ResponseError;
};

const ResponseErrorNotificationDetails: FC<
  ResponseErrorNotificationDetailsProps
> = ({ responseError }) => {
  const { t } = useTranslation();
  const { success } = useNotification();

  const onCopy = useCallback(() => {
    copy(responseError.errorDetails, {
      format: 'text/plain',
      onCopy: () =>
        success({
          duration: 2,
          message: t('common.copyOnClick.success.message'),
        }),
    });
  }, [responseError.errorDetails, success, t]);

  return (
    <AntCollapse
      data-testid={'error-notification-details-collapse'}
      ghost
      items={[
        {
          children: (
            <div className={styles.responseErrorNotificationDetailsWrapper}>
              <AntInput.TextArea
                autoSize={true}
                className={styles.responseErrorNotificationDetailsText}
                data-testid="error-notification-details-content"
                readOnly={true}
                value={`${responseError.errorDetails}\n`}
                variant="filled"
                wrap="soft"
              />
              <Button
                className={styles.responseErrorNotificationDetailsCopyButton}
                data-testid="error-notification-details-copy-button"
                icon={<CopyOutlined />}
                onClick={onCopy}
                size="small"
              >
                {t('common.button.copy')}
              </Button>
            </div>
          ),
          className: styles.responseErrorNotificationDetails,
          key: 'error details',
          label: t('common.label.moreDetails'),
        },
      ]}
    />
  );
};

export default ResponseErrorNotificationDetails;
